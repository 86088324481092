<template>
  <div>
    <Row class="m-b-5">

      <i-col :xs="12" :sm="12" :md="8" :lg="8">
        <Label class="title">资源编号</Label>
        <span>{{resourceModel.code}}</span>
      </i-col>
      <i-col :xs="12" :sm="12" :md="8" :lg="8">
        <Label class="title">所属资产</Label>
        <span>{{resourceModel.assetName}}</span>
      </i-col>
      <i-col :xs="12" :sm="12" :md="8" :lg="8">
        <Label class="title">站点名称</Label>
        <span>{{resourceModel.stationName }}</span>
      </i-col>
    </Row>
    <Row class="m-b-5">
      <i-col :xs="12" :sm="12" :md="8" :lg="8">
        <Label class="title">站点级别</Label>
        <span>{{resourceModel.stationLevelName}}</span>
      </i-col>
      <i-col :xs="12" :sm="12" :md="8" :lg="8">
        <Label class="title">成品尺寸</Label>
        <span>{{resourceModel.makewidth}}mm * {{resourceModel.makeheight}}mm</span>
      </i-col>
      <i-col :xs="12" :sm="12" :md="8" :lg="8">
        <Label class="title">见光尺寸</Label>
        <span>{{resourceModel.finalwidth }}mm * {{resourceModel.finalheight }}mm</span>
      </i-col>
    </Row>
    <Row class="m-b-5">
       <i-col :xs="12" :sm="12" :md="8" :lg="8">
        <Label class="title">梯牌数量</Label>
        <span>{{resourceModel.side}}</span>
      </i-col>
      <i-col :xs="12" :sm="12" :md="8" :lg="8">
        <Label class="title">是否可用</Label>
        <span v-if="resourceModel.enabled">可用</span>
        <span v-else>不可用</span>
      </i-col>
      <i-col :xs="12" :sm="12" :md="8" :lg="8">
        <Label class="title">所在位置</Label>
        <span> 楼层（ {{resourceModel.floor}} 层） {{resourceModel.positionName}}</span>
      </i-col>
    </Row>
  </div>
</template>

<script>
export default {
  props: {
    resourceModel: {
      type: Object,
      required: true
    }
  }
}
</script>
